"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateMergeConfig = void 0;
const contracts_1 = require("@soleran/contracts");
const module_1 = require("@soleran/module");
function generateMergeConfig(...args) {
  return __awaiter(this, void 0, void 0, function* () {
    const [nodes, options] = args;
    const {
      moduleNode
    } = options;
    if (!moduleNode) {
      const {
        moduleId,
        modules
      } = options;
      return _generateMergeConfig(nodes, yield module_1.ModuleNode.fromModules(modules, moduleId));
    } else return _generateMergeConfig(nodes, moduleNode.root);
  });
}
exports.generateMergeConfig = generateMergeConfig;
function _generateMergeConfig(nodes, rootNode) {
  const mergeNodes = _getMergeFieldNodes(nodes);
  const joinConfigLookup = new Map();
  const fieldConfigLookup = new Map();
  const fieldLookup = new Map();
  let nextModuleId = 1;
  let nextFieldIndex = 0;
  mergeNodes.forEach(node => {
    var _a;
    //TODO; Cleanup/Refactor/Implement Async Logic?
    const {
      moduleId,
      fieldId
    } = node;
    if (fieldConfigLookup.has(fieldId)) return; //Config already exists
    //Create Module Configs
    const currentNode = rootNode.get(moduleId);
    if (!currentNode) throw new Error(`Merge Config Error - Module Missing ${moduleId}`);
    const nodesFromRoot = rootNode.getNodesTo(currentNode.id);
    nodesFromRoot === null || nodesFromRoot === void 0 ? void 0 : nodesFromRoot.reduce((parentConfigId, node) => {
      var _a;
      const configs = (_a = joinConfigLookup.get(node.id)) !== null && _a !== void 0 ? _a : [];
      let joinConfig = configs.find(conf => conf.parentModuleConfigId == parentConfigId);
      if (!joinConfig) {
        joinConfig = {
          id: nextModuleId++,
          parentModuleConfigId: parentConfigId !== null && parentConfigId !== void 0 ? parentConfigId : undefined,
          joinOption: contracts_1.JoinOption.Left,
          leftModuleId: '',
          //TODO; Fix for changes
          leftFieldId: '',
          rightModuleId: node.id,
          rightFieldId: '',
          isRightModuleParent: !!parentConfigId
        };
        configs.push(joinConfig);
        joinConfigLookup.set(node.id, configs);
      }
      return joinConfig.id;
    }, null);
    //Create Field Config
    if (!fieldLookup.has(fieldId)) {
      (_a = currentNode.data.fields) === null || _a === void 0 ? void 0 : _a.forEach(field => fieldLookup.set(field.id, field));
    }
    let field = fieldLookup.get(fieldId);
    if (!field) throw new Error(`Merge Config Error - Field Missing ${fieldId}`);
    const fieldConfig = {
      index: nextFieldIndex++,
      formulaType: 0,
      isLink: false,
      label: field.name,
      fieldId,
      moduleId
    };
    fieldConfigLookup.set(fieldId, fieldConfig);
  });
  return {
    joinConfigs: [...joinConfigLookup.values()].flat(),
    fieldConfigs: [...fieldConfigLookup.values()]
  };
}
function _getMergeFieldNodes(nodes, mergeNodes = []) {
  nodes.forEach(node => {
    if (node.type == contracts_1.MERGE_FIELD_TYPE) {
      mergeNodes.push(node);
    } else if (Array.isArray(node.children)) {
      _getMergeFieldNodes(node.children, mergeNodes);
    }
  });
  return mergeNodes;
}
