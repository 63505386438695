"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeTemplate = void 0;
function mergeTemplate(html, fields, data) {
  return ''; //TODO; Implement with '@soleran/control' library
}
exports.mergeTemplate = mergeTemplate;
